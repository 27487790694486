.b-parination {
  display: flex;
  align-items: center;
  &__list {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      padding: 0 5px;
    }
    a {
      background-color: transparent;
      width: 40px;
      height: 40px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      border: 2px solid $c_main;
      @include transition();
      @include sm() {
        width: 30px;
        height: 30px;
        &:empty {
          display: none;
        }
      }
      &,
      &:focus,
      &:visited {
        color: $c_main;
        text-decoration: none;
      }
      &:hover,
      &:active,
      &.active {
        color: #fff;
        background-color: $c_main;
        text-decoration: none;
      }
    }
  }
  &__style {
    margin-left: 10px;
    flex: 1 1 auto;
    height: 2px;
    background: $c_main;
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -20px;
      width: 40px;
      height: 40px;
      background: $c_main;
      border-radius: 50%;
    }
  }
}