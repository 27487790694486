.b-newsbox {
  margin-bottom: 20px;
  &__header {
    color: #fff;
    background: $c_main;
    display: flex;
    padding: 20px 30px;
    justify-content: space-between;
  }

  &__content {

  }
  &__item {
    padding: 20px 10px;
    border-bottom: 1px solid $c_gray;
    @include transition();
    display: flex;
    align-items: flex-end;
    font-size: rem(18px);
    &,
    &:focus,
    &:visited {
      color: $c_gray;
      text-decoration: none;
    }
    &:hover,
    &:active {
      color: $c_main;
      text-decoration: none;
    }
    &_content {
      flex: 1 1 auto;
    }
    &_footer {
      font-size: rem(14px);
      flex: 0 0 auto;
      padding: 0 20px;
    }
  }
  &__date {
    color: $c_main;
    margin-bottom: 20px;
  }
  &__title {

  }
}