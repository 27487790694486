.b-bignews {
  display: block;
  font-size: rem(20px);
  line-height: 1.1;
  margin-bottom: 30px;
  @include transition();
  &,
  &:focus,
  &:visited {
    color: $c_main;
    text-decoration:none;
  }
  &:hover,
  &:active {
    color: $c_second;
    text-decoration:none;
  }
  &__img {
    position: relative;
    width: 100%;
    display: block;
    background: $c_gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 20px;
    &::after {
      content: '';
      display: block;
      padding-top: 100*9/16+0%;
    }
  }
  &__content {

  }
  &__date {

  }
  &__title {

  }
}