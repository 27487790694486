.header {
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff;
  &__nav {
    display: flex;
    justify-content: flex-end;
    border-bottom: 4px solid $c_main;
    position: relative;
    @include sm() {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      background: transparentize($c_main, 0.05);
      justify-content: center;
      align-items: center;
      transform: translate(-100%, 0);
      flex-direction: column;
      @include transition();

      padding: 40px 15px;
      .js-menu {
        position: absolute;
        top: 10px;
        right: 15px;
        &,
        &:focus,
        &:visited {
          color: #fff;
        }
        &:hover,
        &:active {
          color: #fff;
        }
      }
      &.active {
        transform: translate(0, 0);
      }
    }
    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $c_main;
      position: absolute;
      bottom: -10px;
      right: -1px;
    }
  }
  &__menu {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: rem(18px);
    @include sm() {
      text-align: center;
      flex-direction: column;
    }
    li {
      padding: 10px 30px 5px;
    }
    a {
      display: inline-block;
      @include transition();
      &,
      &:focus,
      &:visited {
        color: $c_gray;
        text-decoration: none;
        @include sm() {
          color: #fff;
        }
      }
      &:hover,
      &:active {
        color: $c_main;
        text-decoration: none;
        @include sm() {
          color: $c_gray;
        }
      }
    }
  }
}

.stylebbtn {

  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid currentColor;
  &,
  &:focus,
  &:visited {
    color: $c_main;
    text-decoration: none;
  }
  &:hover,
  &:active {
    color: $c_gray;
    text-decoration: none;
  }
}