.section {
  padding: rem(100px) 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &_slim {
    padding: 50px 0;
    @include sm() {
      padding: 20px 0;
    }
  }
  &__description {
    color: $c_gray;
    font-size: rem(19px);
    &_inher {
      color: inherit;
    }
  }
  &__bg {
    &-main {
      color: #fff;
      background-color: $c_main;
    }
  }
  &__svgwave {
    position: absolute;
    top: 5px;
    @include sm() {
      top: 15px;
    }
  }
}
