html, body {
  font-family: $global-font-family;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100%;
  overflow-x: hidden;
  @include sm {
    font-size: 12px;
  }
  @include md {
    font-size: 14px;
  }
}

.header {
  flex: 0 0 auto;
}

.main {
  flex: 1 1 auto;
  //display: flex;
  //flex-direction: column;
}

.footer {
  flex: 0 0 auto;
}

.color {
  &-gray {
    color: $c_gray !important;
  }
  &-main {
    color: $c-main !important;
  }
}

.flex-grow {
  flex: 1 1 auto;
}

a.contact {
  display: inline-block;
  @include transition();
  &,
  &:focus,
  &:visited {
    color: #fff;
    text-decoration: none;
  }
  &:hover,
  &:active {
    color: #eee;
    text-decoration: none;
  }
}