.b-biz {
  flex: 1 1 100%;
  width: 100%;
  display: block;
  margin-bottom: 30px;
  &__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
  }
  &__title {
    font-size: rem(24px);
    margin: 0;
    color: $c_main;
  }
  &__icon {
    flex: 0 0 auto;
    img {
      width: 90px;
      height: 90px;
      object-position: center bottom;
      object-fit: contain;
    }
  }
  &__content {

  }
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    border-top: 1px solid $c_main;
    li {
      color: $c_gray;
      font-size: rem(18px);
      margin: 5px 0 0;
      padding-bottom: 0.5rem;
      line-height: 1.3rem;
      min-height: 1.3*3rem+0.5;
      border-bottom: 1px solid $c_main;
      display: flex;
      align-items: flex-end;
    }
  }
}