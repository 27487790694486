.b-obj {
  display: flex;
  color: #fff;
  @include md() {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__box {
    display: block;
    flex: 0 0 100/7+0%;
    border-top: 2px solid #fff;
    padding: 0 10px;
    margin-bottom: 20px;
    position: relative;
    @include md() {
      flex: 0 0 100/4+0%;
    }
    &::before {
      content: "↓";
      text-align: center;
      display: block;
      font-size: rem(60px);
      line-height: 0.6;
      transform: scale(0.5, 1);
    }
    &::after {
      content: '';
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
      background: currentColor;
    }
  }
  &__icon {
    margin: 20px auto 10px;
    background: #fff;
    border: 2px solid $c_gray;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 50px;
      height: 50px;
      display: block;
    }
  }
  &__title {
    text-align: center;
    font-size: rem(14px);
    color: inherit;
    min-height: 3em;
    margin: 0;
  }
  &__content {
    font-size: rem(12px);
  }
  &__list {

  }
}