.b-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__text {
    max-width: 100%;
    flex: 0 0 auto;
    padding-right: 40px;
  }
  &__styler {
    height: 2px;
    background: currentColor;
    flex: 1 1 auto;
    margin-bottom: 30px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: currentColor;
      position: absolute;
      top: -12px;
      left: -1px;
    }
  }
}