.m-form {
  &__box {
    margin-bottom: 20px;
  }
  &__label {

  }
  &__input {
    display: block;
    width: 100%;
    border: none;
    color: #fff;
    background-color: transparent;
    border-bottom: 1px solid #fff;
    outline: none;
    padding: 5px;
  }
  &__btn {
    border: 1px solid currentColor;
    background-color: transparent;
    display: inline-block;
    padding: 5px 30px;
    @include transition();

    &,
    &:focus,
    &:visited {
      color: #fff;
      text-decoration: none;
    }
    &:hover,
    &:active {
      cursor: pointer;
      color: $c_main;
      background-color: #fff;
      text-decoration: none;
    }
  }
}