.b-steper {
  display: flex;
  margin-bottom: rem(30px);
  align-items: center;

  @include sm() {
    flex-wrap: wrap;
  }
  &__header {
    flex: 0 0 50%;
    margin-bottom: 70px;
    @include sm() {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 40px;
    }
    display: flex;
    border-bottom: 1px solid $c_gray;
    align-items: flex-end;
    padding-right: 20px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 10%;
      flex: 0 0 auto;
    }
    &::before {
      position: absolute;
      right: 0;
      bottom: -10px;
      content: '';
      width: 20px;
      height: 20px;
      background: #fff;
      background-image: url(../img/arr.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      @include sm() {
        transform: rotate(90deg)
      }
    }
  }
  &__count {
    font-size: rem(50px);
    line-height: 1;
    color: $c_main;
  }
  &__title {
    padding: 10px 20px;
    font-size: rem(18px);
    line-height: 1;
    color: $c_main;
    flex: 1 1 auto;
  }
  &__icon {
    flex: 0 0 auto;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    bottom: -35px;
    position: relative;
    background: $c_main;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 40px;
      height: 40px;
      display: block;
    }
  }
  &__content {
    flex: 0 0 50%;
    font-size: rem(18px);
    color: $c_gray;
    padding: 20px;
    @include sm() {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}