.b-collapse {
  display: block;
  margin-bottom: rem(30px);
  &__title {
    display: block;
    border-bottom: 2px solid currentColor;
    margin-bottom: rem(20px);
    font-size: rem(22px);
    text-transform: uppercase;
    position: relative;
    @include transition();

    &::after {
      @include transition();
      content: '';
      display: block;
      width: 26px;
      height: 26px;
      position: absolute;
      bottom: -13px;
      border-radius: 50%;
      background: currentColor;
      right: -1px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px auto;
      background-image: url('../img/arr_2.svg');
    }
    &,
    &:focus,
    &:visited {
      color: $c_main;
      text-decoration: none;
    }
    &:hover,
    &:active {
      color: $c_second;
      text-decoration: none;
    }
    &.active {
      color: $c_gray;
      &::after {
        transform: rotate(180deg);
      }
    }

    &_nostyle {
      border-color: transparent;
      &::after {
        display: none;
      }
    }
  }
  &__content {
    color: $c_main;
    font-size: rem(20px);
  }
}