.intro {
  background-image: url('../img/intro.png');
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto  100%;
  min-height: 60vh;
  display: flex;
  align-items: center;
  margin-top: 60px;
  &__title {
    font-size: rem(71px);
    color: $c_gray;
    &-before {
      color: $c_main;
      font-size: rem(23px);
      text-transform: uppercase;
    }

    &-after {
      color: $c_main;
      font-size: rem(21px);
      text-transform: uppercase;
    }
    &-big {
      font-size: rem(37px);
      color: $c_main;
      text-transform: uppercase;
    }
  }
}