.title {
  font-size: rem(28px);
  color: inherit;
  margin-bottom: rem(30px);
  &_main {
    color: $c_main;
  }
  &_big {
    font-size: rem(44px);
  }
}